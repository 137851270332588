// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCKegWJpmD__mayjfrIBJMkXIIxn_e63Fc",
  authDomain: "chatdac-74a36.firebaseapp.com",
  projectId: "chatdac-74a36",
  storageBucket: "chatdac-74a36.appspot.com",
  messagingSenderId: "54518307256",
  appId: "1:54518307256:web:95e83caef242600c7cd74d",
  measurementId: "G-3Q0R0N4C71"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const goggleAuthProvider = new GoogleAuthProvider();

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(firebaseApp);
const analytics = getAnalytics(firebaseApp);

export { auth, goggleAuthProvider, db, analytics };
