import React from "react";
import Hint from "./Hint";

const HintBox = ({ title, hints, hideOnSmallScreens }) => {
  return (
    <div className={"hintBox " + hideOnSmallScreens ?? ""}>
      <p className="hintBoxTitle">{title}</p>
      {hints.map((hint, i) => <Hint key={i} text={hint} />)}
    </div>
  );
};

export default HintBox;
