import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import DacIcon from "../assets/img/dac-icon.svg";
import { AuthContext } from "../context/AuthContext";

const Login = () => {
  const { dispatch } = useContext(AuthContext);
  const [nickname, setNickname] = useState("");

  const navigate = useNavigate();

  const navigateToChatBot = () => {
    dispatch({ type: "LOGIN", payload: {displayName: nickname} });
    navigate("/");
  }

  return (
    <>
      <div className="loginContainer">
        <div className="loginContainerContent">
          <img style={{marginBottom: "20px"}} src={DacIcon} alt="DAC logo" />
          <h1>Witamy - Chatbot DAC.digital</h1>
          <p>Szybka i inteligentna pomoc</p>
          <div className="loginButtonWrapper">
          <div className="nicknameForm">
            <p>Podaj swój nick</p>
            <input
              type="text"
              name="loginPassword"
              id="loginPassword"
              placeholder="Twój nick"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  navigateToChatBot();
              }}}
              required
              autoComplete="password"
            />
            <button type="submit" onClick={navigateToChatBot}>Kontynuuj</button>
          </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
