import React from "react";

const Hint = ({ text }) => {
  return (
    <div className="hint">
      {text}
    </div>
  );
};

export default Hint;
