import React from "react";
import BotResponse from "./BotResponse";
import HintBox from "./HintBox";

const IntroSection = () => {
  return (
    <div id="introsection">
      <p style={{color: "#00404F"}}>Chatbot DAC.digital</p>
      <div style={{ height: "12px" }}></div>
      <div style={{color: "#333333", fontWeight: "700", fontSize: "18px"}}><BotResponse response="Szybka i inteligentna pomoc" /></div>
      <p style={{color: "#333333"}}>Zadawaj pytania, otrzymuj odpowiedzi</p>
      <div className="hintSection">
      <HintBox title="Przykłady" hints={["Czym zajmuje się DAC.digital?", "Gdzie siedzibę ma DAC.digital?", "Jakie technologie wykorzystuje DAC.digital?"]} />
      <HintBox hideOnSmallScreens="hideOnSmallScreen" title="Funkcje" hints={["Odpowiadaj na pytania i rozwiązuj problemy", "Automatyzuj pracę i zadania", "Wyszukuj informacje"]} />
      <HintBox hideOnSmallScreens="hideOnSmallScreen" title="Ograniczenia" hints={["Brak empatii", "Brak kontekstu", "Trudności w interpretacji i rozumieniu emocji"]} />
      </div>
    </div>
  );
};

export default IntroSection;
