import React from "react";

const NewChat = ({ setChatLog, setShowMenu }) => {
  return (
    <button className="newChat" onClick={() => {
           setChatLog([]);
           setShowMenu(false);}}>
      Nowe zapytanie
    </button>
  );
};

export default NewChat;
